export const Configurations = {
  //Release variables
  environment: "release",
  mintSystemConfigApiBaseUrl: "https://mintsystemconfig-prod.azurefd.us/api",
  mintOrchUIBaseUrl: "____mintOrchUIBaseUrl____",
  authority: "https://login.microsoftonline.us/86b1dded-d548-4e1b-9584-900494145773",
  clientId: "df885391-29a7-4c37-a085-830a7d6c7ff7",
  appInsightsInstrumentationKey: "9744bb57-3650-f215-8b35-0906c8f1fa17",
  appInsightsEndpoint: "applicationinsights.us",
  scope: "7cd6706f-c27f-47f7-8d5b-f131080c5fae/readAccess",

  // MSF Localhost
  // environment: "local", // Sandbox
  // mintSystemConfigApiBaseUrl: "https://localhost:44390/api",
  // mintOrchUIBaseUrl: "https://app-mintorchui-uat.azurewebsites.us",
  // authority: "https://login.microsoftonline.us/86b1dded-d548-4e1b-9584-900494145773",
  // clientId: "983c9455-ba62-481b-8389-58ee0f8b2f4e",
  // appInsightsInstrumentationKey: "657dc810-6917-f511-b5c8-7340bcad5c5c",
  // appInsightsEndpoint: "https://dc.applicationinsights.us/v2/track",
  // scope: "c893efb0-0578-4263-84e4-e01a2d3624cb/readAccess",


  // Localhost
  // environment: "local", // Sandbox
  // mintSystemConfigApiBaseUrl: "https://localhost:44390/api",
  // mintOrchUIBaseUrl: "https://app-mintorchui-dev.azurewebsites.net",
  // authority: "https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47",
  // clientId: "10782160-f825-4da7-83c2-1104a025fb18",
  // appInsightsInstrumentationKey: "ec09c28f-11d2-4461-b115-c7504d98ae08",
  // appInsightsEndpoint: "0",
  // scope: "0239ea81-04c4-4347-87f3-8e27a60d13f0/readAccess",


  // Sandbox
  // environment: "sandbox",
  // mintSystemConfigApiBaseUrl: "https://app-mintsystemconfigapi-p-dev.azurewebsites.net/api",
  // mintOrchUIBaseUrl: "https://app-mintorchui-dev.azurewebsites.net",
  // authority: "https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47",
  // clientId: "10782160-f825-4da7-83c2-1104a025fb18",
  // appInsightsInstrumentationKey: "ec09c28f-11d2-4461-b115-c7504d98ae08",
  // appInsightsEndpoint: "0",
  // scope: "0239ea81-04c4-4347-87f3-8e27a60d13f0/readAccess",


  // UAT
  // environment: "uat",
  // mintSystemConfigApiBaseUrl: "https://app-mintsystemconfigapi-p-uat.azurewebsites.net/api",
  // mintOrchUIBaseUrl: "https://app-mintorchui-uat.azurewebsites.net",
  // authority: "https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47",
  // clientId: "b4de7623-7e2a-41f3-a72d-97f2f1145523",
  // appInsightsInstrumentationKey: "ac43edd1-739a-4c5f-8e8e-8448b885259d",
  // appInsightsEndpoint: "0",
  // scope: "52cf53ff-af8d-4c8c-95b5-b4790192f804/readAccess",

};
